
    .container {
        display: flex;
        align-items: baseline;
      }
    
      .input-group {
        margin-bottom: 10px; /* Adjust the margin as needed */
      }
    
      @media screen and (max-width: 600px) {
        .container {
          flex-direction: column;
        }
    
        .input-group {
          margin-bottom: 0; /* Reset margin for smaller screens */
        }
      }